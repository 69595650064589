import { default as ai_45form_45builderifB00x5r2zMeta } from "/home/forge/indiaai-forms.bluelup.in/client/pages/ai-form-builder.vue?macro=true";
import { default as editRxwWApH67LMeta } from "/home/forge/indiaai-forms.bluelup.in/client/pages/forms/[slug]/edit.vue?macro=true";
import { default as indexaAN8FpQvPeMeta } from "/home/forge/indiaai-forms.bluelup.in/client/pages/forms/[slug]/index.vue?macro=true";
import { default as indexVCbfQNk1BpMeta } from "/home/forge/indiaai-forms.bluelup.in/client/pages/forms/[slug]/show/index.vue?macro=true";
import { default as index2oCpMq9tloMeta } from "/home/forge/indiaai-forms.bluelup.in/client/pages/forms/[slug]/show/integrations/index.vue?macro=true";
import { default as shareDBwV7RM4dnMeta } from "/home/forge/indiaai-forms.bluelup.in/client/pages/forms/[slug]/show/share.vue?macro=true";
import { default as statspQN0egfrHHMeta } from "/home/forge/indiaai-forms.bluelup.in/client/pages/forms/[slug]/show/stats.vue?macro=true";
import { default as submissionsdbaxacMxLIMeta } from "/home/forge/indiaai-forms.bluelup.in/client/pages/forms/[slug]/show/submissions.vue?macro=true";
import { default as showKetUHUQEbxMeta } from "/home/forge/indiaai-forms.bluelup.in/client/pages/forms/[slug]/show.vue?macro=true";
import { default as guestg7wIoLSZC0Meta } from "/home/forge/indiaai-forms.bluelup.in/client/pages/forms/create/guest.vue?macro=true";
import { default as indexYIEzD1h6tBMeta } from "/home/forge/indiaai-forms.bluelup.in/client/pages/forms/create/index.vue?macro=true";
import { default as homec3tcldFqWJMeta } from "/home/forge/indiaai-forms.bluelup.in/client/pages/home.vue?macro=true";
import { default as indexeRqZQXKkY7Meta } from "/home/forge/indiaai-forms.bluelup.in/client/pages/index.vue?macro=true";
import { default as loginc55iWDliFEMeta } from "/home/forge/indiaai-forms.bluelup.in/client/pages/login.vue?macro=true";
import { default as emailS3mIyJCPJVMeta } from "/home/forge/indiaai-forms.bluelup.in/client/pages/password/email.vue?macro=true";
import { default as _91token_93scWCPGLfrlMeta } from "/home/forge/indiaai-forms.bluelup.in/client/pages/password/reset/[token].vue?macro=true";
import { default as pricingAvlZrvjhfUMeta } from "/home/forge/indiaai-forms.bluelup.in/client/pages/pricing.vue?macro=true";
import { default as privacy_45policymmTdTc8E60Meta } from "/home/forge/indiaai-forms.bluelup.in/client/pages/privacy-policy.vue?macro=true";
import { default as registerxazCsBCCgxMeta } from "/home/forge/indiaai-forms.bluelup.in/client/pages/register.vue?macro=true";
import { default as accountH1SwCXVUSAMeta } from "/home/forge/indiaai-forms.bluelup.in/client/pages/settings/account.vue?macro=true";
import { default as adminZecWLSNsHhMeta } from "/home/forge/indiaai-forms.bluelup.in/client/pages/settings/admin.vue?macro=true";
import { default as billing163yQBSxdJMeta } from "/home/forge/indiaai-forms.bluelup.in/client/pages/settings/billing.vue?macro=true";
import { default as connections3PhYqB5KErMeta } from "/home/forge/indiaai-forms.bluelup.in/client/pages/settings/connections.vue?macro=true";
import { default as indexCO9oah4WTRMeta } from "/home/forge/indiaai-forms.bluelup.in/client/pages/settings/index.vue?macro=true";
import { default as passwordoVJsiqDCbWMeta } from "/home/forge/indiaai-forms.bluelup.in/client/pages/settings/password.vue?macro=true";
import { default as profilerF1uYrOOGcMeta } from "/home/forge/indiaai-forms.bluelup.in/client/pages/settings/profile.vue?macro=true";
import { default as workspacel2zBAzFsbtMeta } from "/home/forge/indiaai-forms.bluelup.in/client/pages/settings/workspace.vue?macro=true";
import { default as settingsaROXwee5a0Meta } from "/home/forge/indiaai-forms.bluelup.in/client/pages/settings.vue?macro=true";
import { default as errorLiXZkx4UpgMeta } from "/home/forge/indiaai-forms.bluelup.in/client/pages/subscriptions/error.vue?macro=true";
import { default as successqdO3zkN09LMeta } from "/home/forge/indiaai-forms.bluelup.in/client/pages/subscriptions/success.vue?macro=true";
import { default as _91slug_93FopeHIdcMsMeta } from "/home/forge/indiaai-forms.bluelup.in/client/pages/templates/[slug].vue?macro=true";
import { default as indexFHqtHJZXhBMeta } from "/home/forge/indiaai-forms.bluelup.in/client/pages/templates/index.vue?macro=true";
import { default as _91slug_93N1RTZC87j4Meta } from "/home/forge/indiaai-forms.bluelup.in/client/pages/templates/industries/[slug].vue?macro=true";
import { default as my_45templateszXLtaK6WeCMeta } from "/home/forge/indiaai-forms.bluelup.in/client/pages/templates/my-templates.vue?macro=true";
import { default as _91slug_93d0krudflK8Meta } from "/home/forge/indiaai-forms.bluelup.in/client/pages/templates/types/[slug].vue?macro=true";
import { default as terms_45conditionssKLh2vj5JsMeta } from "/home/forge/indiaai-forms.bluelup.in/client/pages/terms-conditions.vue?macro=true";
export default [
  {
    name: ai_45form_45builderifB00x5r2zMeta?.name ?? "ai-form-builder",
    path: ai_45form_45builderifB00x5r2zMeta?.path ?? "/ai-form-builder",
    meta: ai_45form_45builderifB00x5r2zMeta || {},
    alias: ai_45form_45builderifB00x5r2zMeta?.alias || [],
    redirect: ai_45form_45builderifB00x5r2zMeta?.redirect,
    component: () => import("/home/forge/indiaai-forms.bluelup.in/client/pages/ai-form-builder.vue").then(m => m.default || m)
  },
  {
    name: editRxwWApH67LMeta?.name ?? "forms-slug-edit",
    path: editRxwWApH67LMeta?.path ?? "/forms/:slug()/edit",
    meta: editRxwWApH67LMeta || {},
    alias: editRxwWApH67LMeta?.alias || [],
    redirect: editRxwWApH67LMeta?.redirect,
    component: () => import("/home/forge/indiaai-forms.bluelup.in/client/pages/forms/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexaAN8FpQvPeMeta?.name ?? "forms-slug",
    path: indexaAN8FpQvPeMeta?.path ?? "/forms/:slug()",
    meta: indexaAN8FpQvPeMeta || {},
    alias: indexaAN8FpQvPeMeta?.alias || [],
    redirect: indexaAN8FpQvPeMeta?.redirect,
    component: () => import("/home/forge/indiaai-forms.bluelup.in/client/pages/forms/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: showKetUHUQEbxMeta?.name ?? undefined,
    path: showKetUHUQEbxMeta?.path ?? "/forms/:slug()/show",
    meta: showKetUHUQEbxMeta || {},
    alias: showKetUHUQEbxMeta?.alias || [],
    redirect: showKetUHUQEbxMeta?.redirect,
    component: () => import("/home/forge/indiaai-forms.bluelup.in/client/pages/forms/[slug]/show.vue").then(m => m.default || m),
    children: [
  {
    name: indexVCbfQNk1BpMeta?.name ?? "forms-slug-show",
    path: indexVCbfQNk1BpMeta?.path ?? "",
    meta: indexVCbfQNk1BpMeta || {},
    alias: indexVCbfQNk1BpMeta?.alias || [],
    redirect: indexVCbfQNk1BpMeta?.redirect,
    component: () => import("/home/forge/indiaai-forms.bluelup.in/client/pages/forms/[slug]/show/index.vue").then(m => m.default || m)
  },
  {
    name: index2oCpMq9tloMeta?.name ?? "forms-slug-show-integrations",
    path: index2oCpMq9tloMeta?.path ?? "integrations",
    meta: index2oCpMq9tloMeta || {},
    alias: index2oCpMq9tloMeta?.alias || [],
    redirect: index2oCpMq9tloMeta?.redirect,
    component: () => import("/home/forge/indiaai-forms.bluelup.in/client/pages/forms/[slug]/show/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: shareDBwV7RM4dnMeta?.name ?? "forms-slug-show-share",
    path: shareDBwV7RM4dnMeta?.path ?? "share",
    meta: shareDBwV7RM4dnMeta || {},
    alias: shareDBwV7RM4dnMeta?.alias || [],
    redirect: shareDBwV7RM4dnMeta?.redirect,
    component: () => import("/home/forge/indiaai-forms.bluelup.in/client/pages/forms/[slug]/show/share.vue").then(m => m.default || m)
  },
  {
    name: statspQN0egfrHHMeta?.name ?? "forms-slug-show-stats",
    path: statspQN0egfrHHMeta?.path ?? "stats",
    meta: statspQN0egfrHHMeta || {},
    alias: statspQN0egfrHHMeta?.alias || [],
    redirect: statspQN0egfrHHMeta?.redirect,
    component: () => import("/home/forge/indiaai-forms.bluelup.in/client/pages/forms/[slug]/show/stats.vue").then(m => m.default || m)
  },
  {
    name: submissionsdbaxacMxLIMeta?.name ?? "forms-slug-show-submissions",
    path: submissionsdbaxacMxLIMeta?.path ?? "submissions",
    meta: submissionsdbaxacMxLIMeta || {},
    alias: submissionsdbaxacMxLIMeta?.alias || [],
    redirect: submissionsdbaxacMxLIMeta?.redirect,
    component: () => import("/home/forge/indiaai-forms.bluelup.in/client/pages/forms/[slug]/show/submissions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: guestg7wIoLSZC0Meta?.name ?? "forms-create-guest",
    path: guestg7wIoLSZC0Meta?.path ?? "/forms/create/guest",
    meta: guestg7wIoLSZC0Meta || {},
    alias: guestg7wIoLSZC0Meta?.alias || [],
    redirect: guestg7wIoLSZC0Meta?.redirect,
    component: () => import("/home/forge/indiaai-forms.bluelup.in/client/pages/forms/create/guest.vue").then(m => m.default || m)
  },
  {
    name: indexYIEzD1h6tBMeta?.name ?? "forms-create",
    path: indexYIEzD1h6tBMeta?.path ?? "/forms/create",
    meta: indexYIEzD1h6tBMeta || {},
    alias: indexYIEzD1h6tBMeta?.alias || [],
    redirect: indexYIEzD1h6tBMeta?.redirect,
    component: () => import("/home/forge/indiaai-forms.bluelup.in/client/pages/forms/create/index.vue").then(m => m.default || m)
  },
  {
    name: homec3tcldFqWJMeta?.name ?? "home",
    path: homec3tcldFqWJMeta?.path ?? "/home",
    meta: homec3tcldFqWJMeta || {},
    alias: homec3tcldFqWJMeta?.alias || [],
    redirect: homec3tcldFqWJMeta?.redirect,
    component: () => import("/home/forge/indiaai-forms.bluelup.in/client/pages/home.vue").then(m => m.default || m)
  },
  {
    name: indexeRqZQXKkY7Meta?.name ?? "index",
    path: indexeRqZQXKkY7Meta?.path ?? "/",
    meta: indexeRqZQXKkY7Meta || {},
    alias: indexeRqZQXKkY7Meta?.alias || [],
    redirect: indexeRqZQXKkY7Meta?.redirect,
    component: () => import("/home/forge/indiaai-forms.bluelup.in/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginc55iWDliFEMeta?.name ?? "login",
    path: loginc55iWDliFEMeta?.path ?? "/login",
    meta: loginc55iWDliFEMeta || {},
    alias: loginc55iWDliFEMeta?.alias || [],
    redirect: loginc55iWDliFEMeta?.redirect,
    component: () => import("/home/forge/indiaai-forms.bluelup.in/client/pages/login.vue").then(m => m.default || m)
  },
  {
    name: emailS3mIyJCPJVMeta?.name ?? "password-email",
    path: emailS3mIyJCPJVMeta?.path ?? "/password/email",
    meta: emailS3mIyJCPJVMeta || {},
    alias: emailS3mIyJCPJVMeta?.alias || [],
    redirect: emailS3mIyJCPJVMeta?.redirect,
    component: () => import("/home/forge/indiaai-forms.bluelup.in/client/pages/password/email.vue").then(m => m.default || m)
  },
  {
    name: _91token_93scWCPGLfrlMeta?.name ?? "password-reset-token",
    path: _91token_93scWCPGLfrlMeta?.path ?? "/password/reset/:token()",
    meta: _91token_93scWCPGLfrlMeta || {},
    alias: _91token_93scWCPGLfrlMeta?.alias || [],
    redirect: _91token_93scWCPGLfrlMeta?.redirect,
    component: () => import("/home/forge/indiaai-forms.bluelup.in/client/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: pricingAvlZrvjhfUMeta?.name ?? "pricing",
    path: pricingAvlZrvjhfUMeta?.path ?? "/pricing",
    meta: pricingAvlZrvjhfUMeta || {},
    alias: pricingAvlZrvjhfUMeta?.alias || [],
    redirect: pricingAvlZrvjhfUMeta?.redirect,
    component: () => import("/home/forge/indiaai-forms.bluelup.in/client/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policymmTdTc8E60Meta?.name ?? "privacy-policy",
    path: privacy_45policymmTdTc8E60Meta?.path ?? "/privacy-policy",
    meta: privacy_45policymmTdTc8E60Meta || {},
    alias: privacy_45policymmTdTc8E60Meta?.alias || [],
    redirect: privacy_45policymmTdTc8E60Meta?.redirect,
    component: () => import("/home/forge/indiaai-forms.bluelup.in/client/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: registerxazCsBCCgxMeta?.name ?? "register",
    path: registerxazCsBCCgxMeta?.path ?? "/register",
    meta: registerxazCsBCCgxMeta || {},
    alias: registerxazCsBCCgxMeta?.alias || [],
    redirect: registerxazCsBCCgxMeta?.redirect,
    component: () => import("/home/forge/indiaai-forms.bluelup.in/client/pages/register.vue").then(m => m.default || m)
  },
  {
    name: settingsaROXwee5a0Meta?.name ?? undefined,
    path: settingsaROXwee5a0Meta?.path ?? "/settings",
    meta: settingsaROXwee5a0Meta || {},
    alias: settingsaROXwee5a0Meta?.alias || [],
    redirect: settingsaROXwee5a0Meta?.redirect,
    component: () => import("/home/forge/indiaai-forms.bluelup.in/client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: accountH1SwCXVUSAMeta?.name ?? "settings-account",
    path: accountH1SwCXVUSAMeta?.path ?? "account",
    meta: accountH1SwCXVUSAMeta || {},
    alias: accountH1SwCXVUSAMeta?.alias || [],
    redirect: accountH1SwCXVUSAMeta?.redirect,
    component: () => import("/home/forge/indiaai-forms.bluelup.in/client/pages/settings/account.vue").then(m => m.default || m)
  },
  {
    name: adminZecWLSNsHhMeta?.name ?? "settings-admin",
    path: adminZecWLSNsHhMeta?.path ?? "admin",
    meta: adminZecWLSNsHhMeta || {},
    alias: adminZecWLSNsHhMeta?.alias || [],
    redirect: adminZecWLSNsHhMeta?.redirect,
    component: () => import("/home/forge/indiaai-forms.bluelup.in/client/pages/settings/admin.vue").then(m => m.default || m)
  },
  {
    name: billing163yQBSxdJMeta?.name ?? "settings-billing",
    path: billing163yQBSxdJMeta?.path ?? "billing",
    meta: billing163yQBSxdJMeta || {},
    alias: billing163yQBSxdJMeta?.alias || [],
    redirect: billing163yQBSxdJMeta?.redirect,
    component: () => import("/home/forge/indiaai-forms.bluelup.in/client/pages/settings/billing.vue").then(m => m.default || m)
  },
  {
    name: connections3PhYqB5KErMeta?.name ?? "settings-connections",
    path: connections3PhYqB5KErMeta?.path ?? "connections",
    meta: connections3PhYqB5KErMeta || {},
    alias: connections3PhYqB5KErMeta?.alias || [],
    redirect: connections3PhYqB5KErMeta?.redirect,
    component: () => import("/home/forge/indiaai-forms.bluelup.in/client/pages/settings/connections.vue").then(m => m.default || m)
  },
  {
    name: indexCO9oah4WTRMeta?.name ?? "settings",
    path: indexCO9oah4WTRMeta?.path ?? "",
    meta: indexCO9oah4WTRMeta || {},
    alias: indexCO9oah4WTRMeta?.alias || [],
    redirect: indexCO9oah4WTRMeta?.redirect,
    component: () => import("/home/forge/indiaai-forms.bluelup.in/client/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: passwordoVJsiqDCbWMeta?.name ?? "settings-password",
    path: passwordoVJsiqDCbWMeta?.path ?? "password",
    meta: passwordoVJsiqDCbWMeta || {},
    alias: passwordoVJsiqDCbWMeta?.alias || [],
    redirect: passwordoVJsiqDCbWMeta?.redirect,
    component: () => import("/home/forge/indiaai-forms.bluelup.in/client/pages/settings/password.vue").then(m => m.default || m)
  },
  {
    name: profilerF1uYrOOGcMeta?.name ?? "settings-profile",
    path: profilerF1uYrOOGcMeta?.path ?? "profile",
    meta: profilerF1uYrOOGcMeta || {},
    alias: profilerF1uYrOOGcMeta?.alias || [],
    redirect: profilerF1uYrOOGcMeta?.redirect,
    component: () => import("/home/forge/indiaai-forms.bluelup.in/client/pages/settings/profile.vue").then(m => m.default || m)
  },
  {
    name: workspacel2zBAzFsbtMeta?.name ?? "settings-workspace",
    path: workspacel2zBAzFsbtMeta?.path ?? "workspace",
    meta: workspacel2zBAzFsbtMeta || {},
    alias: workspacel2zBAzFsbtMeta?.alias || [],
    redirect: workspacel2zBAzFsbtMeta?.redirect,
    component: () => import("/home/forge/indiaai-forms.bluelup.in/client/pages/settings/workspace.vue").then(m => m.default || m)
  }
]
  },
  {
    name: errorLiXZkx4UpgMeta?.name ?? "subscriptions-error",
    path: errorLiXZkx4UpgMeta?.path ?? "/subscriptions/error",
    meta: errorLiXZkx4UpgMeta || {},
    alias: errorLiXZkx4UpgMeta?.alias || [],
    redirect: errorLiXZkx4UpgMeta?.redirect,
    component: () => import("/home/forge/indiaai-forms.bluelup.in/client/pages/subscriptions/error.vue").then(m => m.default || m)
  },
  {
    name: successqdO3zkN09LMeta?.name ?? "subscriptions-success",
    path: successqdO3zkN09LMeta?.path ?? "/subscriptions/success",
    meta: successqdO3zkN09LMeta || {},
    alias: successqdO3zkN09LMeta?.alias || [],
    redirect: successqdO3zkN09LMeta?.redirect,
    component: () => import("/home/forge/indiaai-forms.bluelup.in/client/pages/subscriptions/success.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FopeHIdcMsMeta?.name ?? "templates-slug",
    path: _91slug_93FopeHIdcMsMeta?.path ?? "/templates/:slug()",
    meta: _91slug_93FopeHIdcMsMeta || {},
    alias: _91slug_93FopeHIdcMsMeta?.alias || [],
    redirect: _91slug_93FopeHIdcMsMeta?.redirect,
    component: () => import("/home/forge/indiaai-forms.bluelup.in/client/pages/templates/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexFHqtHJZXhBMeta?.name ?? "templates",
    path: indexFHqtHJZXhBMeta?.path ?? "/templates",
    meta: indexFHqtHJZXhBMeta || {},
    alias: indexFHqtHJZXhBMeta?.alias || [],
    redirect: indexFHqtHJZXhBMeta?.redirect,
    component: () => import("/home/forge/indiaai-forms.bluelup.in/client/pages/templates/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93N1RTZC87j4Meta?.name ?? "templates-industries-slug",
    path: _91slug_93N1RTZC87j4Meta?.path ?? "/templates/industries/:slug()",
    meta: _91slug_93N1RTZC87j4Meta || {},
    alias: _91slug_93N1RTZC87j4Meta?.alias || [],
    redirect: _91slug_93N1RTZC87j4Meta?.redirect,
    component: () => import("/home/forge/indiaai-forms.bluelup.in/client/pages/templates/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: my_45templateszXLtaK6WeCMeta?.name ?? "templates-my-templates",
    path: my_45templateszXLtaK6WeCMeta?.path ?? "/templates/my-templates",
    meta: my_45templateszXLtaK6WeCMeta || {},
    alias: my_45templateszXLtaK6WeCMeta?.alias || [],
    redirect: my_45templateszXLtaK6WeCMeta?.redirect,
    component: () => import("/home/forge/indiaai-forms.bluelup.in/client/pages/templates/my-templates.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93d0krudflK8Meta?.name ?? "templates-types-slug",
    path: _91slug_93d0krudflK8Meta?.path ?? "/templates/types/:slug()",
    meta: _91slug_93d0krudflK8Meta || {},
    alias: _91slug_93d0krudflK8Meta?.alias || [],
    redirect: _91slug_93d0krudflK8Meta?.redirect,
    component: () => import("/home/forge/indiaai-forms.bluelup.in/client/pages/templates/types/[slug].vue").then(m => m.default || m)
  },
  {
    name: terms_45conditionssKLh2vj5JsMeta?.name ?? "terms-conditions",
    path: terms_45conditionssKLh2vj5JsMeta?.path ?? "/terms-conditions",
    meta: terms_45conditionssKLh2vj5JsMeta || {},
    alias: terms_45conditionssKLh2vj5JsMeta?.alias || [],
    redirect: terms_45conditionssKLh2vj5JsMeta?.redirect,
    component: () => import("/home/forge/indiaai-forms.bluelup.in/client/pages/terms-conditions.vue").then(m => m.default || m)
  }
]